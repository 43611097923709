import React from 'react'

const SocialMediaIcon = () => {
  return (
    <div>
      <div className="social-menu">
        <ul>
          <li><a href="https://www.instagram.com/1wn.co/" target="blank"><i className="fab fa-instagram"></i></a></li>
          <li><a href="https://www.linkedin.com/company/1wn-one-world-one-network/" target="blank"><i className="fab fa-linkedin-in"></i></a></li>
          <li><a href="https://www.facebook.com/1wn.co"><i className="fab fa-facebook" target="blank"></i></a></li>
          <li><a href="https://www.youtube.com/@1wn_co" target="blank"><i className="fab fa-youtube"></i></a></li>
        </ul>
      </div>
    </div>
  )
}

export default SocialMediaIcon
