import FormikControl from 'components/wrapperComponents/FormikControl';
import { msgTypes } from 'constants/msgTypes';
import { Form, Formik } from 'formik';
import { FeaturedInNewsletter } from 'model/screensModels/featuredInNewsletter/FeaturedInNewsletter';
import React, { useState } from 'react'
import FeaturedInNewsletterSchema from 'schemas/featuredInNewsletter/FeaturedInNewsletterSchema';
import { FeaturedInNewsletterService } from 'services/menu/featuredInNewsletter/FeaturedInNewsletterService';

const FeaturedInNewsletterScreen = () => {
    const [initialValue, setInitialValue] = useState(new FeaturedInNewsletter());
    const categoryList = [
        { key: "Member News", value: "Member News" },
        { key: "Trade News & Facts", value: "Trade News & Facts" },
        { key: "Others", value: "Others" }
    ]

    //Prepare Data for API
    const prepareRequestBody = async (values) => {
        const prepareData = new FeaturedInNewsletter();

        //to assing id based on action 
        if (("" + initialValue.featuredInNewsletterId)?.length > 0) {
            prepareData.featuredInNewsletterId = initialValue.featuredInNewsletterId;
        } else {
            prepareData.featuredInNewsletterId = values.featuredInNewsletterId ? values.featuredInNewsletterId : '';
        }

        prepareData.name = values.name ? values.name : '';
        prepareData.category = values.category ? values.category : '';
        prepareData.message = values.message ? values.message : '';
        prepareData.email = values.email ? values.email : '';

        return prepareData;

    }

    const onSubmit = async (values) => {
        const requestData = await prepareRequestBody(values);
        const res = await FeaturedInNewsletterService.saveFeaturedInNewsletter(requestData);
        if (res.statusMsg === msgTypes.SUCCESS.msg) {
            return true
        } else {
            return false
        }
    }

    return (

        <Formik
            enableReinitialize={true}
            initialValues={initialValue}
            validationSchema={FeaturedInNewsletterSchema}
            onSubmit={async(values, { resetForm }) => {
                const flag = await onSubmit(values);
                if (flag) {
                    resetForm();
                }
            }}
        >
            {
                ({ isValid, dirty, isSubmitting, values }) => (

                    <div>
                        {/* <!--CTA One Start--> */}
                        <section className="cta-one">
                            <div className="container">
                                <div className="cta-one__inner">
                                    <div className="cta-one__bg-img ctaStyle"></div>
                                    <div className="cta-one__content-box">
                                        <div className="row">
                                            <div className="col-xl-5">
                                                <h3 className="cta-one__title">Get Featured in our
                                                    <br></br> Newsletter
                                                </h3>
                                                <div className="cta-one__contact-box">
                                                    <div className="icon">
                                                        <span className="icon-phone"></span>
                                                    </div>
                                                    <div className="content">
                                                        <p>Need help?</p>
                                                        <h3><a href="tel:+971 54 522 3903">+971 54 522 3903</a></h3>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-xl-7">
                                                <Form>
                                                    <div className="row">
                                                        <div className="col-xl-6 col-lg-6 mb-3">
                                                            {/* <div className="faq-one__input-box"> */}
                                                                {/* <input type="text" name="name" placeholder="Name" required="" /> */}
                                                                <FormikControl control='textfield'
                                                                    name='name'
                                                                    className='form-control'
                                                                    placeholder="Name"
                                                                    // className="input-form-data"
                                                                />
                                                            {/* </div> */}
                                                        </div>
                                                        <div className="col-xl-6 col-lg-6 mb-3">
                                                            {/* <div className="faq-one__input-box"> */}
                                                                {/* <input type="email" name="email" placeholder="Category" required="" /> */}
                                                                <FormikControl
                                                                    control='autocomplete'
                                                                    className='form-control selected-cat'
                                                                    name="category"
                                                                    placeholder="Category"
                                                                    options={categoryList}
                                                                    keyfield="key"
                                                                    valuefield="value"
                                                                    // className="input-form-data"

                                                                />
                                                            {/* </div> */}
                                                        </div>
                                                        <div className="col-xl-6 col-lg-6 mb-3">
                                                            {/* <div className="faq-one__input-box"> */}
                                                                {/* <input type="text" name="text" placeholder="Message" required="" /> */}
                                                                <FormikControl control='textfield'
                                                                    name='email'
                                                                    className='form-control'
                                                                    placeholder="Email"
                                                                    // className="input-form-data"
                                                                />
                                                            {/* </div> */}
                                                        </div>
                                                        <div className="col-xl-6 col-lg-6 mb-3">
                                                            {/* <div className="faq-one__input-box"> */}
                                                                {/* <input type="text" name="text" placeholder="Message" required="" /> */}
                                                                <FormikControl control='textfield'
                                                                    name='message'
                                                                    placeholder="Message"
                                                                    className='form-control'
                                                                    // className="input-form-data"
                                                                />
                                                            {/* </div> */}
                                                        </div>
                                                        <div className="col-xl-12 text-center">
                                                            {/* <div className="faq-one__btn-box"> */}
                                                                <button type="submit" className="thm-btn faq-one__btn" disabled={!dirty || !isValid}>Submit
                                                                    <span></span></button>
                                                                {/* <FormikControl  className="thm-btn faq-one__btn" control='button' label="Submit" type="submit"  /> */}
                                                            {/* </div> */}
                                                        </div>
                                                    </div>
                                                </Form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        {/* <!--CTA One End--> */}
                    </div>
                )
            }
        </Formik>


    )
}

export default FeaturedInNewsletterScreen
