export default class ApiUrl {
    //Production
    public static baseUrl: string = "https://api.oneworldnetwork.net/api";
    public static fileBaseUrl: string = "https://api.oneworldnetwork.net/media/";


    //Rohit Local url
    // public static baseUrl: string = "http://192.168.1.13:8081/api";
    // public static fileBaseUrl: string = "http://192.168.1.13:8081/media/";

    public static midUrl: string = '/api';

}