import React, { useState } from 'react'
import { Form, Formik } from 'formik';
import { NewsletterSubscribers } from 'model/screensModels/newsletterSubscribers/NewsletterSubscribers';
import NewsletterSubscribersSchema from 'schemas/newsletterSubscribers/NewsletterSubscribersSchema';
import FormikControl from 'components/wrapperComponents/FormikControl';
import { NewsletterSubscribersService } from 'services/menu/newsletterSubscribers/NewsletterSubscribersService';
import { msgTypes } from 'constants/msgTypes';
import FeaturedInNewsletterScreen from './FeaturedInNewsletterScreen';

const Footer = () => {
    const [initialValue, setInitialValue] = useState(new NewsletterSubscribers());

    //Prepare Data for API
    const prepareRequestBody = async (values) => {
        const prepareData = new NewsletterSubscribers();

        //to assing id based on action 
        if (("" + initialValue.newsletterSubscriberId)?.length > 0) {
            prepareData.newsletterSubscriberId = initialValue.newsletterSubscriberId;
        } else {
            prepareData.newsletterSubscriberId = values.newsletterSubscriberId ? values.newsletterSubscriberId : '';
        }

        prepareData.emailId = values.emailId ? values.emailId : '';

        return prepareData;

    }

    const onSubmit = async (values) => {
        const requestData = await prepareRequestBody(values);
        const res = await NewsletterSubscribersService.saveNewsletterSubscriber(requestData);
        if (res.statusMsg === msgTypes.SUCCESS.msg) {
            return true
        } else {
            return false
        }
    }

    return (
        <div>
            {/* Begin:: Get started  Newsletter*/}
            <FeaturedInNewsletterScreen />
            {/* End :: Get started Newsletter*/}

            {/* <!--Site Footer Start--> */}
            <footer className="site-footer">
                {/* <!-- <div className="site-footer__shape-1 float-bob-y">
                <img src="images/shapes/footer-shape-1.png" alt=""></img>
            </div> --> */}
                <span className="gl-testimonial-map position-absolute"><img src="images/backgrounds/map3.png"
                    alt=""></img></span>
                <div className="site-footer__top">
                    <div className="container">
                        <div className="site-footer__top-inner">
                            <div className="row">
                                <div className="col-xl-3 col-lg-6 col-md-6 wow fadeInUp" data-wow-delay="100ms">
                                    <div className="footer-widget__column footer-widget__about">
                                        {/* <!-- <div className="footer-widget__logo">
                                        <a href="index.html"><img src="images/resources/logo-1wn.png" alt=""></img></a>
                                    </div> --> */}
                                        {/* <p className="footer-widget__about-text">Welcome to 1WN - One World One Network , a global network curated to help elevate businesses & forge bonds in a secured environment, of its exclusive members from the logistics and freight forwarding industry</p> */}
                                        <div className="footer-widget__title-box">
                                            <h3 className="footer-widget__title">Our Offices</h3>
                                        </div>
                                        <ul className="footer-widget__about-contact list-unstyled">

                                            <li>
                                                <div className="icon">
                                                    <span className="icon-location location-icon"></span>
                                                </div>
                                                <p>Radisson Blu Plaza Hotel,<br></br>
                                                    Delhi Airport, New Delhi -110037</p>
                                            </li>
                                            <li>
                                                <div className="icon">
                                                    <span className="icon-location location-icon"></span>
                                                </div>
                                                <p>IFZA Business Park,<br></br>
                                                    Dubai Silicon Oasis, Dubai</p>
                                            </li>
                                            <li>
                                                <div className="icon">
                                                    <span className="icon-location location-icon"></span>
                                                </div>
                                                <p>511, 8W Building,<br></br> DAFZA Dubai Airport Free<br></br> Zone,
                                                    Dubai Airport, UAE .</p>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-xl-3 col-lg-6 col-md-6 wow fadeInUp" data-wow-delay="200ms">
                                    <div className="footer-widget__column footer-widget__company">
                                        <div className="footer-widget__title-box">
                                            <h3 className="footer-widget__title">Quick Links</h3>
                                        </div>
                                        <ul className="footer-widget__company-list list-unstyled">
                                            <li>
                                                <a href="/faqs">FAQs</a>
                                            </li>
                                            <li>
                                                {/* <!-- <a href="mission.html">Our Mission</a> --> */}
                                            </li>
                                            <li>
                                                <a href="/membership-benifits">Membership Benefits</a>
                                            </li>
                                            <li>
                                                <a href="/cookie-policies">Cookie Policies</a>
                                            </li>
                                            <li>
                                                <a href="/privacy-policies">Privacy Policies</a>
                                            </li>
                                            <li>
                                                <a href="/terms-and-conditions">Terms and Conditions</a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-xl-3 col-lg-6 col-md-6 wow fadeInUp" data-wow-delay="300ms">
                                    <div className="footer-widget__column footer-widget__services">
                                        <div className="footer-widget__title-box">
                                            <h3 className="footer-widget__title">Highlights</h3>
                                        </div>
                                        <ul className="footer-widget__services-list list-unstyled">
                                            <li>
                                                <a href="/event-and-conference"><span className="icon-angle-left"></span>Event & Conference</a>
                                            </li>
                                            <li>
                                                <a href="/news-and-media"><span className="icon-angle-left"></span>News &
                                                    Media</a>
                                            </li>
                                            <li>
                                                <a href="/conferences"><span className="icon-angle-left"></span>1WN Conferences</a>
                                            </li>
                                            <li>
                                                <a href="/post-event-gallery"><span className="icon-angle-left"></span>Post Event Gallery</a>
                                            </li>
                                            <li>
                                                <a href="/csr"><span className="icon-angle-left"></span>CSR</a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-xl-3 col-lg-6 col-md-6 wow fadeInUp" data-wow-delay="400ms">
                                    <div className="footer-widget__column footer-widget__newsletter">
                                        <div className="footer-widget__title-box">
                                            <h3 className="footer-widget__title">Newsletter</h3>
                                        </div>
                                        <p className="footer-widget__newsletter-text">Freight Forwarding Fraternity to join us</p>

                                        <Formik
                                            enableReinitialize={true}
                                            initialValues={initialValue}
                                            validationSchema={NewsletterSubscribersSchema}
                                            onSubmit={async (values, { resetForm }) => {
                                                const res = await onSubmit(values);
                                                if (res) {
                                                    resetForm();
                                                }
                                            }}
                                        >
                                            {
                                                ({ isValid, setFieldValue, dirty, isSubmitting, values }) => (
                                                    <Form className="footer-widget__newsletter-form ">
                                                        <div className="footer-widget__newsletter-form-input-box">
                                                            <FormikControl control='textfield'
                                                                name='emailId'
                                                                className='form-control'
                                                                placeholder="Email"
                                                            />
                                                            <button type="submit" className="footer-widget__newsletter-btn" disabled={!dirty || !isValid}>
                                                                <span className="icon-paper-plane" ></span>
                                                            </button>
                                                        </div>
                                                    </Form>
                                                )
                                            }
                                        </Formik>
                                    
                                <div className="mc-form__response"></div>
                            </div>
                            <ul className="footer-widget__about-contact list-unstyled mt-4">
                                <li>
                                    <div className="icon">
                                        <span className="icon-envolop email-icon"></span>
                                    </div>
                                    <p><a href="mailto:debra.holt@example.com">info@oneworldnetwork.co</a></p>
                                </li>
                                <li>
                                    <div className="icon">
                                        <span className="icon-call phone-call"></span>
                                    </div>
                                    <p><a href="tel:+971 54 522 3903">+971 54 522 3903</a></p>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
        </div>
                </div>
    <div className="site-footer__bottom">
        <div className="container">
            <div className="site-footer__bottom-inner text-center">
                <p className="site-footer__bottom-text">©2024 All Rights Reserved, Designed & Developed by <a
                    href="https://csgtech.in/" target="_blank"> CSG.</a></p>
            </div>
        </div>
    </div>
            </footer>
    {/* Site FooterEnd */}
        </div>
    )
}

export default Footer
