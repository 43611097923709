import React from 'react'
import Carousel from 'react-multi-carousel';

export const CountryFlag = () => {
    const responsive = {
        superLargeDesktop: {
            breakpoint: { max: 1700, min: 1200 },
            items: 8
        },
        desktop: {
            breakpoint: { max: 1200, min: 992 },
            items: 4
        },
        tablet: {
            breakpoint: { max: 992, min: 768 },
            items: 3
        },
        mobile: {
            breakpoint: { max: 768, min: 0 },
            items: 2
        }
    };

  return (
    <section className="brand-one">
                <div className="container">
                    {/* <div className="thm-swiper__slider swiper-container" data-swiper-options='{"spaceBetween": 100,
                "slidesPerView": 5,
                "loop": true,
                "navigation": {
                    "nextEl": "#brand-one__swiper-button-next",
                    "prevEl": "#brand-one__swiper-button-prev"
                },
                "autoplay": { "delay": 5000 },
                "breakpoints": {
                    "0": {
                        "spaceBetween": 30,
                        "slidesPerView": 1
                    },
                    "375": {
                        "spaceBetween": 30,
                        "slidesPerView": 1
                    },
                    "575": {
                        "spaceBetween": 30,
                        "slidesPerView": 2
                    },
                    "767": {
                        "spaceBetween": 50,
                        "slidesPerView": 3
                    },
                    "991": {
                        "spaceBetween": 50,
                        "slidesPerView": 4
                    },
                    "1199": {
                        "spaceBetween": 100,
                        "slidesPerView": 5
                    },
                    "1199": {
                        "spaceBetween": 100,
                        "slidesPerView": 5
                    }
                }}'>
                            <div className="swiper-wrapper">
                                <div className="swiper-slide">
                                    <img src="images/brand/brand-1-1.png" alt=""></img>
                                </div>
                                <div className="swiper-slide">
                                    <img src="images/brand/brand-1-2.png" alt=""></img>
                                </div>
                                <div className="swiper-slide">
                                    <img src="images/brand/brand-1-3.png" alt=""></img>
                                </div>
                                <div className="swiper-slide">
                                    <img src="images/brand/brand-1-4.png" alt=""></img>
                                </div>
                                <div className="swiper-slide">
                                    <img src="images/brand/brand-1-5.png" alt=""></img>
                                </div>
                                <div className="swiper-slide">
                                    <img src="images/brand/brand-1-1.png" alt=""></img>
                                </div>
                                <div className="swiper-slide">
                                    <img src="images/brand/brand-1-2.png" alt=""></img>
                                </div>
                                <div className="swiper-slide">
                                    <img src="images/brand/brand-1-3.png" alt=""></img>
                                </div>
                                <div className="swiper-slide">
                                    <img src="images/brand/brand-1-4.png" alt=""></img>
                                </div>
                                <div className="swiper-slide">
                                    <img src="images/brand/brand-1-5.png" alt=""></img>
                                </div>
                            </div>
                        </div> */}
                    <h2
                        className
                        =
                        "brand-one__text count-box section-title__title"
                    > 1WN &nbsp;Welcomes you to our growing<br></br> global network in <span
                        className
                        =
                        "count-text"
                        data-stop
                        =
                        "40"
                        data-speed
                        =
                        "1500"
                    >00</span><span>+</span> Countries</h2>
                    <Carousel arrows={true} responsive={responsive} showDots={false} autoPlay={true} transitionDuration={200} infinite={true}>

                        <div className="swiper-slide">
                            <img src="images/country-flags/afghanistan.jpg" alt="afghanistan"></img>
                        </div>
                        <div className="swiper-slide">
                            <img src="images/country-flags/belgium.jpg" alt="belgium"></img>
                        </div>
                        <div className="swiper-slide">
                            <img src="images/country-flags/uzbekistan.png" alt="uzbekistan"></img>
                        </div>
                        <div className="swiper-slide">
                            <img src="images/country-flags/us.jpg" alt="united-states-of-america"></img>
                        </div>
                        <div className="swiper-slide">
                            <img src="images/country-flags/london.jpg" alt="london"></img>
                        </div>
                        <div className="swiper-slide">
                            <img src="images/country-flags/uae.jpg" alt="united-arab-emirates"></img>
                        </div>
                        <div className="swiper-slide">
                            <img src="images/country-flags/turkish.jpg" alt="turkish"></img>
                        </div>
                        <div className="swiper-slide">
                            <img src="images/country-flags/thailand.jpg" alt="thailand"></img>
                        </div>
                        <div className="swiper-slide">
                            <img src="images/country-flags/spain.jpg" alt="spain"></img>
                        </div>
                        <div className="swiper-slide">
                            <img src="images/country-flags/singapore.jpg" alt="Singapore"></img>
                        </div>
                        <div className="swiper-slide">
                            <img src="images/country-flags/portugal.jpg" alt="portugal"></img>
                        </div>
                        <div className="swiper-slide">
                            <img src="images/country-flags/poland.jpg" alt="poland"></img>
                        </div>
                        <div className="swiper-slide">
                            <img src="images/country-flags/pakistan.jpg" alt="pakistan"></img>
                        </div>
                        <div className="swiper-slide">
                            <img src="images/country-flags/oman.jpg" alt="oman"></img>
                        </div>
                        <div className="swiper-slide">
                            <img src="images/country-flags/kenya.jpg" alt="kenya"></img>
                        </div>
                        <div className="swiper-slide">
                            <img src="images/country-flags/japan.jpg" alt="japan"></img>
                        </div>
                        <div className="swiper-slide">
                            <img src="images/country-flags/italy.jpg" alt="italy"></img>
                        </div>
                        <div className="swiper-slide">
                            <img src="images/country-flags/india.jpg" alt="india"></img>
                        </div>
                        <div className="swiper-slide">
                            <img src="images/country-flags/germany.jpg" alt="germany"></img>
                        </div>
                        <div className="swiper-slide">
                            <img src="images/country-flags/france.jpg" alt="france"></img>
                        </div>
                        <div className="swiper-slide">
                            <img src="images/country-flags/canada.jpg" alt="canada"></img>
                        </div>
                        <div className="swiper-slide">
                            <img src="images/country-flags/brazilian.jpg" alt="brazilian"></img>
                        </div>
                        <div className="swiper-slide">
                            <img src="images/country-flags/benin.jpg" alt="benin"></img>
                        </div>
                    </Carousel>
                </div>
            </section>
  )
}
